import React, { FC } from 'react';
import './SearchBar.scss';
import { ReactComponent as Searchlogo } from '../../_assets/_icons/searchlogo.svg';

type Props = {
  onChange: (e) => void;
  value: string;
};

const SearchBar: FC<Props> = ({ onChange, value }) => (
  <div className="searchbar">
    <input
      className="search"
      value={value}
      onChange={onChange}
      type="search"
      id="project-search"
      placeholder="Start searching"
      spellCheck="false"
    ></input>
    <i className="search-logo">
      <Searchlogo />
    </i>
  </div>
);

export default SearchBar;
