import { useQuery } from 'react-query';
import { IProject } from '../models/Project';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getProject(id: string): Promise<IProject> {
  return HttpClient.get<IProject>(`projects/${id}`);
}

export function useGetProject(id: string): UseQueryResult<IProject, ApiError> {
  return useQuery<IProject, ApiError>('getProject', () => getProject(id),);
}
