import Button from '../../components/Button';
import FormItem from '../../components/FormItem';
import React, { FC, FormEvent } from 'react';
import useForm from '../../hooks/UseForm';
import { IForgotPasswordForm } from 'src/models/ForgotPassword';
import { useTranslation } from 'react-i18next';
import { useForgotPassword } from '../../queries/useForgotPassword';
import { Link } from 'react-router-dom';
import backbutton from '../../_assets/_icons/backbutton.svg';
import ErrorMessage from '../../components/ErrorMessage';

import './ForgotPasswordPage.scss';

const initialForm: IForgotPasswordForm = {
  email: '',
};

const ForgotPasswordPage: FC = () => {
  const { form, setFormAttribute } = useForm(initialForm);
  const { isLoading, isError, mutate, error, isSuccess } = useForgotPassword();
  const { t } = useTranslation();

  const resetPassword = (event: FormEvent) => {
    event.preventDefault();
    mutate(form);
  };

  return (
    <section className="forgot-password-page">
      {isSuccess ? (
        <div>
          <h3 className="title">{t('titleForgotPasswordConfirmation')}</h3>
          <span className="description">
            {t('txtForgotPasswordConfirmationDescription1')}
            <b>{' ' + form.email + ' '}</b>
            {t('txtForgotPasswordConfirmationDescription2')}
          </span>
          <Link className="link" to="/auth/login">
            <Button type="button" text={t('btnForgotPasswordConfirmationClose')} loading={isLoading} />
          </Link>
        </div>
      ) : (
        <div>
          <Link className="backbutton" to="/auth/login">
            <img className="backbutton-icon" src={backbutton} alt="icapps reference app logo" />
            {t('btnForgotPasswordBack')}
          </Link>
          {error ? (
            <ErrorMessage message={error?.statusCode == 400 ? t('errorForgotPasswordEmailNotExist') : t('FORGOT_UNKOWN-ERROR')} isVisible={isError} />
          ) : (
            <h3 className="title">{t('titleForgotPassword')}</h3>
          )}
          <form onSubmit={resetPassword}>
            <span className="description">{t('txtForgotPasswordDescription')}</span>
            <FormItem
              required
              id="email"
              label={t('lblForgotPasswordEmail')}
              type="email"
              value={form.email}
              onChange={setFormAttribute}
            />
            <Button type="submit" text={t('btnForgotPasswordSend')} loading={isLoading} />
          </form>
        </div>
      )}
    </section>
  );
};

export default ForgotPasswordPage;
