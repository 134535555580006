import { useQuery } from 'react-query';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';
import { ICustomer } from 'src/models/Customer';

export async function getCustomers(): Promise<ICustomer[]> {
  return HttpClient.get<ICustomer[]>('customers?_sort=display_order:asc,label:asc');
}
export function useGetCustomers(): UseQueryResult<ICustomer[], ApiError> {
  return useQuery<ICustomer[], ApiError>('getCustomers', getCustomers);
}
