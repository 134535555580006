import React, { FC } from 'react';
import './GridItem.scss';
import { IProject } from '../../models/Project';
import { numberWithCommas, capitalize } from '../../../../shared/utils/formatting';
import { useTranslation } from 'react-i18next';
import icapps from '../../_assets/_images/icapps.png';

type Props = {
  className?: string;
  project: IProject;
};

const GridItem: FC<Props> = ({ project, className }) => {
  const { t } = useTranslation();

  return (
    <li className={`list-item ${className || ''}`}>
      <div className="hover-container">
        {project.images.length ? <img className="image" src={project.images[0]?.url} alt={project.images[0]?.alternativeText}></img> :<img className="image" src={icapps}></img> }
        <div>
          <ul className="grid-container">
            {project.platforms && <li className="platform">{project.platforms.map(platform => platform.label).join(', ')}</li>}
            {project.technologies && (
              <li className="technology">{project.technologies.map(technology => technology.label).join(', ')}</li>
            )}
            {project.sector && <li className="sector">{project.sector.label}</li>}
            {project.budget && <li className="budget">€ {numberWithCommas(project.budget)}</li>}
            {project.services?.length ? (
              <li className="care">{project.services?.map(service => service.label).join(', ')}</li>
            ) : (
              <li className="care">{t('txtOverviewGridHoverNoCare')}</li>
            )}
            {project.teams && <li className="team">{project.teams.map(team => team.name).join(', ')}</li>}
            {project.type && <li>{capitalize(project.type)}</li>}
            {project.account_manager && <li className="account-manager">{project.account_manager.name}</li>}
          </ul>
        </div>
      </div>
      <div className="project-title">{project.name}</div>
    </li>
  );
};
export default GridItem;
