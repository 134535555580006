import React, { FC, useEffect, useState } from 'react';
import { useLogout } from '../../queries/useLogout';
import SideMenu from '../../components/SideMenu';
import { useGetUser } from '../../queries/UseGetUser';
import icappsLogo from '../../_assets/_images/logo_icapps_overview.svg';
import burgerMenu from '../../_assets/_icons/burgermenu.svg';
import closeMenu from '../../_assets/_icons/closemenu.svg';
import { Link, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { useGetCollections } from '../../queries/useGetCollections';
import GridItem from '../../components/GridItem';
import { useTranslation } from 'react-i18next';
import { useGetProjects } from '../../queries/useGetProjects';
import { ProjectQueryParams } from '../../models/ProjectQueryParams';
import classNames from 'classnames';
import removeallfromcollection from '../../_assets/_icons/removefromcollection.svg';
import { IPutProjectCollection } from '../../models/Collection';
import { usePutProjectCollection } from '../../queries/usePutProjectCollection';
import sendproject from '../../_assets/_icons/sendproject.svg';
import Mail from '../../components/Mail';

import './CollectionPage.scss';

const CollectionPage: FC = () => {
  const { t } = useTranslation();
  const { data: dataUser } = useGetUser();
  const { logout } = useLogout();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { isLoading, data: collectionsData, refetch: refetchCollections } = useGetCollections(dataUser?.id);
  const { mutate: putProjectCollection } = usePutProjectCollection();
  const [removeAll, setRemoveAll] = useState(false);
  const [formatEmail, setFormatEmail] = useState(false);

  const paramsGetProjects: ProjectQueryParams = {
    id: collectionsData?.[0]?.projects?.map(projects => projects.id),
  };

  const { data: projectsData, refetch: refetchProjects } = useGetProjects('published_at:asc', paramsGetProjects);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    refetchCollections;
    refetchProjects;
  });

  const removeAllFromCollection = () => {
    setRemoveAll(false);
    const paramsRemoveProject: IPutProjectCollection = {
      id: collectionsData[0].id,
      projects: [],
    };
    putProjectCollection(paramsRemoveProject);
  };

  return (
    <section className="collection-page">
      <div className="header">
        <div className={classNames('header-container', { 'right-open': isOpen })}>
          <img onClick={() => setIsOpen(true)} className="icapps-logo" src={icappsLogo}></img>
          {collectionsData?.[0]?.projects.length > 0 && (
            <div>
              <div className="remove-all-from-collection-button" onClick={() => setRemoveAll(true)}>
                <img className="remove-from-collection-logo" src={removeallfromcollection}></img>
                <div className="remove-from-collection-text">{t('btnCollectionRemoveAllProjects')}</div>
              </div>
              <div className="send-button" onClick={() => setFormatEmail(true)}>
                <img className="send-button-logo" src={sendproject}></img>
                <div className="send-button-text">{t('btnDetailSendProject')}</div>
              </div>
            </div>
          )}
        </div>
        <div className="sidemenu">
          <Menu
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            itemListElement="div"
            width={'35rem'}
            disableAutoFocus
            customBurgerIcon={<img src={burgerMenu} />}
            customCrossIcon={<img src={closeMenu} />}
          >
            <SideMenu user={dataUser?.username} onClickLogout={logout} onClickItem={() => setIsOpen(false)} />
          </Menu>
        </div>
      </div>
      <div className="title">{t('titleCollection')}</div>
      {removeAll && (
        <div className="fullscreen-container">
          <div className="remove-all-confirmation-container">
            <div className="remove-all-title">{t('titleCollectionRemoveAllProjectsConfirmation')}</div>
            <div className="remove-all-text">{t('txtCollectionRemoveAllProjectsConfirmation')}</div>
            <div className="buttons-container">
              <button className="button-all-cancel" onClick={() => setRemoveAll(false)}>
                {t('btnCollectionRemoveAllProjectsConfirmationCancel')}
              </button>
              <button className="button-all-remove" onClick={removeAllFromCollection}>
                {t('btnCollectionRemoveAllProjectsConfirmationRemoveAll')}
              </button>
            </div>
          </div>
        </div>
      )}
      {formatEmail && <Mail onClickCancel={() => setFormatEmail(false)} projects={collectionsData?.[0]?.projects} />}
      {isLoading ? (
        <ul className="list">
          {new Array(12).fill(null).map((_, i) => (
            <div className="loading-div" key={i} />
          ))}
        </ul>
      ) : collectionsData?.[0]?.projects?.length ? (
        <ul className="list">
          {collectionsData[0]?.projects?.map(collectionProject => {
            const project = projectsData?.find(p => collectionProject.id === p.id) || collectionProject;
            return (
              <Link className="link" to={`/overview/${project.id}`} key={project.id}>
                <GridItem project={project} />
              </Link>
            );
          })}
        </ul>
      ) : (
        <div className="no-results-container">
          <div className="no-results-title">{t('txtCollectionNoResults1')}</div>
          <div className="no-results-title">{t('txtCollectionNoResults2')}</div>
        </div>
      )}
    </section>
  );
};

export default CollectionPage;
