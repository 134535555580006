import { useEffect, useState } from 'react';
interface IReturnTypes {
  selectArray: string[];
  select: (value: string) => void;
  clear: () => void;
  reset: () => void;
  set: (value: string[]) => void;
  hasChanged: boolean;
}
const useSelectArray = (initial: string[], limit?: number): IReturnTypes => {
  const [selectArray, setSelectArray] = useState<string[]>(initial);
  const [hasChanged, sethasChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(initial) !== JSON.stringify(selectArray)) sethasChanged(true);
    else sethasChanged(false);
  }, [selectArray]);
  const select = (value: string) => {
    if (selectArray.includes(value)) setSelectArray(selectArray.filter(v => v !== value));
    else {
      if (!limit || selectArray.length < limit) setSelectArray([...selectArray, value]);
    }
  };
  const clear = () => setSelectArray([]);
  const reset = () => setSelectArray(initial);
  const set = (value: string[]) => setSelectArray(value);
  return {
    select,
    clear,
    reset,
    set,
    selectArray,
    hasChanged,
  };
};
export default useSelectArray;