import React, { FC, useEffect, useState } from 'react';
import './FilterMenu.scss';
import Select from 'react-select';
import { useGetTechnologies } from '../../queries/useGetTechnologies';
import { useGetPlatforms } from '../../queries/useGetPlatforms';
import { useGetSectors } from '../../queries/useGetSectors';
import { useGetCarePackages } from '../../queries/UseGetCarePackages';
import { useGetAccountManagers } from '../../queries/UseGetAccountManagers';
import { useGetCustomers } from '../../queries/useGetCustomers';
import { useGetTeams } from '../../queries/useGetTeams';
import { Type } from '../../models/Type';
import { ProjectQueryParams } from '../../models/ProjectQueryParams';
import { useTranslation } from 'react-i18next';
import { default as NumberFormat } from 'react-number-format';
import Checkbox from '../Checkbox';

type Props = {
  setQuery: React.Dispatch<React.SetStateAction<ProjectQueryParams>>;
  onClick: () => void;
  query: ProjectQueryParams;
};

const SideMenu: FC<Props> = ({ setQuery, onClick, query }) => {
  const { t } = useTranslation();
  const [reactSelectResetKey, setReactSelectResetKey] = useState<string>('');

  const handleSelect = async (key: keyof ProjectQueryParams, values: number[]) => {
    setQuery(oldQuery => {
      return {
        ...oldQuery,
        [key]: values,
      };
    });
  };

  const handleCheckboxChanged = e => {
    const { name, value, checked } = e.target;

    if (checked) {
      setQuery(oldQuery => {
        const newValue = [...(oldQuery[name] || []), parseInt(value)];
        return {
          ...oldQuery,
          [name]: newValue,
        };
      });
    } else {
      setQuery(oldQuery => {
        const newValue = (oldQuery[name] || []).filter(selected => selected !== parseInt(value));
        return {
          ...oldQuery,
          [name]: newValue,
        };
      });
    }
  };

  const handleCheckboxChangedString = e => {
    const { name, value, checked } = e.target;

    if (checked) {
      setQuery(oldQuery => {
        const newValue = [...(oldQuery[name] || []), value];
        return {
          ...oldQuery,
          [name]: newValue,
        };
      });
    } else {
      setQuery(oldQuery => {
        const newValue = (oldQuery[name] || []).filter(selected => selected !== value);
        return {
          ...oldQuery,
          [name]: newValue,
        };
      });
    }
  };

  const handleNumericInput = (name: string, value: string) => {
    setQuery(oldQuery => {
      return {
        ...oldQuery,
        [name]: parseFloat(value),
      };
    });
  };

  useEffect(() => {
    if (!Object.values(query).length) {
      setReactSelectResetKey('_refresh');
      setTimeout(() => {
        setReactSelectResetKey('');
      }, 10);
    }
  }, [query]);

  const { data: dataTechnologies } = useGetTechnologies();
  const { data: dataPlatforms } = useGetPlatforms();
  const { data: dataSectors } = useGetSectors();
  const { data: dataCustomers } = useGetCustomers();
  const { data: dataAccountManagers } = useGetAccountManagers();
  const { data: dataCarePackages } = useGetCarePackages();
  const { data: dataTeams } = useGetTeams();

  const sectors = dataSectors?.map(sector => {
    return { value: sector.id, label: sector.label };
  });

  const customers = dataCustomers?.map(customer => {
    return { value: customer.id, label: customer.label };
  });

  const accountManagers = dataAccountManagers?.map(accountManager => {
    return { value: accountManager.id, label: accountManager.name };
  });

  const carePackages = dataCarePackages?.map(carPackage => {
    return { value: carPackage.id, label: carPackage.label };
  });

  const teams = dataTeams?.map(team => {
    return { value: team.id, label: team.name };
  });

  const types = Object.keys(Type).filter(t => typeof Type[t as string]);

  return (
    <>
      <div className="filtermenu__reset" onClick={onClick}>
        {t('btnFilterReset')}
      </div>
      <div className="filtermenu__filter">{t('titleFilter')}</div>
      <div className="filtermenu">
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblTechnology')}</div>
          <ul className="filtermenu__list">
            {dataTechnologies?.map(technology => {
              return (
                <li className="filtermenu__listitem" key={technology.id}>
                  <Checkbox
                    title={technology.label}
                    name="technologies"
                    checked={(query.technologies || []).includes(technology.id)}
                    value={String(technology.id)}
                    onChange={e => handleCheckboxChanged(e)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblPlatform')}</div>
          <ul className="filtermenu__list">
            {dataPlatforms?.map(platform => {
              return (
                <li className="filtermenu__listitem" key={platform.id}>
                  <Checkbox
                    title={platform.label}
                    checked={(query.platforms || []).includes(platform.id)}
                    name="platforms"
                    value={String(platform.id)}
                    onChange={e => handleCheckboxChanged(e)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="filtermenu__filtercontainer_small">
          <div className="filtermenu__title">{t('lblSector')}</div>
          <Select
            placeholder={t('phFilterSector')}
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={sectors}
            onChange={values =>
              handleSelect(
                'sector',
                values.map(sector => sector.value),
              )
            }
            key={'sectors' + reactSelectResetKey}
            values={query.sector}
          />
        </div>
        <div className="filtermenu__filtercontainer_small">
          <div className="filtermenu__title">{t('lblCustomer')}</div>
          <Select
            placeholder={t('phFilterCustomer')}
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={customers}
            onChange={values =>
              handleSelect(
                'customer',
                values.map(customer => customer.value),
              )
            }
            key={'customer' + reactSelectResetKey}
            values={query.customer}
          />
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblBudget')}</div>
          <div className="filtermenu__budget">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              allowNegative={false}
              className="budget_input"
              placeholder={t('phFilterBudgetMinimum')}
              name="budget_gt"
              value={query.budget_gt || ''}
              onValueChange={values => handleNumericInput('budget_gt', values.value)}
            ></NumberFormat>
            <div className="separator">-</div>
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              allowNegative={false}
              className="budget_input"
              placeholder={t('phFilterBudgetMaximum')}
              name="budget_lt"
              value={query.budget_lt || ''}
              onValueChange={values => handleNumericInput('budget_lt', values.value)}
            ></NumberFormat>
          </div>
          {query.budget_gt >= 0 && query.budget_lt >= 0 && query.budget_gt > query.budget_lt && (
            <div className="error-budget">{t('errorFilterBudget')}</div>
          )}
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblAccountManager')}</div>
          <Select
            placeholder={t('phFilterAccountManager')}
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={accountManagers}
            onChange={values =>
              handleSelect(
                'account_manager',
                values.map(accountManager => accountManager.value),
              )
            }
            values={query.account_manager}
            key={'account_managers' + reactSelectResetKey}
          />
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblCarePackage')}</div>
          <Select
            placeholder={t('phFilterCarePackage')}
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={carePackages}
            onChange={values =>
              handleSelect(
                'services',
                values.map(carePackage => carePackage.value),
              )
            }
            values={query.services}
            key={'services' + reactSelectResetKey}
          />
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblTeam')}</div>
          <Select
            placeholder={t('phFilterTeams')}
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            options={teams}
            onChange={values =>
              handleSelect(
                'teams',
                values.map(team => team.value),
              )
            }
            values={query.teams}
            key={'teams' + reactSelectResetKey}
          />
        </div>
        <div className="filtermenu__filtercontainer">
          <div className="filtermenu__title">{t('lblType')}</div>
          <ul className="filtermenu__list">
            {types?.map(type => {
              return (
                <li className="filtermenu__listitem" key={type}>
                  <Checkbox
                    title={type}
                    name="type"
                    value={type.toLowerCase()}
                    checked={(query.type || []).includes(type.toLowerCase())}
                    onChange={e => handleCheckboxChangedString(e)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
