import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  component: FC;
  path: string;
}

const UnauthorizedRoute: FC<Props> = ({ component, ...otherProps }) => {
  const renderRoute = () => {
    const RouteComponent = component;

    return !window.sessionStorage.getItem('jwt') ? (
      <RouteComponent {...otherProps} />
    ) : (
      <Redirect to={'/'} />
    );
  };

  return <Route {...otherProps} render={renderRoute} />;
};

export default UnauthorizedRoute;
