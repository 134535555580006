import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HttpClient } from '../../../shared/http';
import { ApiError } from '../../../shared/http/HttpError';
import { IMail, IMailResponse } from '../models/Mail';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function postMail(body: IMail): Promise<IMailResponse> {
  return HttpClient.post<IMailResponse>('mail/projects', body);
}

export function usePostMail(onSuccess?: () => void) {
  const { t } = useTranslation();
  const history = useHistory();
  return useMutation<IMailResponse, ApiError, IMail>(postMail, {
    onSuccess: response => {
      onSuccess?.();
      toast.success(t('textToastMailSentSucces'));
    },
    onError: response => {
      toast.error(t('textToastMailSentError'));
    },
  });
}
