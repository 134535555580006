import Button from '../../components/Button';
import FormItem from '../../components/FormItem';
import React, { FC, FormEvent,  useState} from 'react';
import useForm from '../../hooks/UseForm';
import { useNewPassword } from '../../queries/useNewPassword';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage';
import { Link, useLocation } from 'react-router-dom';
import { INewPasswordForm } from 'src/models/NewPassword';

import './NewPasswordPage.scss';

const initialForm: INewPasswordForm = {
  newPassword: '',
};

function validatePassword(password:string):boolean {
  // Password requirement validation, 1 lowercase, 1 uppercase, 1 number, >=8 characters
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  return (regex.test(password)); 
}

const NewPasswordPage: FC = () => {
  const { form, setFormAttribute } = useForm(initialForm);
  const { isLoading, isError, mutate, error, isSuccess } = useNewPassword();
  const [ isInvalidPassword, setIsInvalidPassword ] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const code = search.split('=')[1];

  const changePassword = (event: FormEvent) => {
    event.preventDefault();
    if(validatePassword(form.newPassword)){
      setIsInvalidPassword(false);
      mutate({code, password: form.newPassword, passwordConfirmation: form.newPassword});
    }
    else{
      setIsInvalidPassword(true);
    }
  };

  return ( 
    <section className="new-password-page">
      {isSuccess ?
        <div>
          <h3 className="title">{t('titleNewPasswordConfirmation')}</h3>
          <span className="description">{t('txtNewPasswordConfirmation')}</span>
          <Link className="link" to="/auth/login">
            <Button type="submit" text={t('btnNewPasswordConfirmationLogin')} loading={isLoading}/>
          </Link>
        </div>
        :
        <div>
          <h3 className="title">{t('titleNewPassword')}</h3>
          <span className="description">{t('titleNewPasswordRequirement')}
            <ul className="requirement-list">
              <li>{t('txtNewPasswordRequirementCharacterAmount')}</li>
              <li>{t('txtNewPasswordRequirementUpperCase')}</li>
              <li>{t('txtNewPasswordRequirementNumber')}</li>
            </ul>
          </span>
          <form onSubmit={changePassword}>
            <FormItem onChange={setFormAttribute} required id="newPassword" label={t('lblNewPassword')} type="password" value={form.newPassword} />
            {error && <ErrorMessage message={t('errorNewPasswordInvalidCode')} isVisible={isError}></ErrorMessage>}
            {isInvalidPassword && <ErrorMessage message={t('errorNewPasswordRequirementError')} isVisible={isInvalidPassword}></ErrorMessage>}
            <Button type="submit" text={t('btnNewPasswordSetPassword')} loading={isLoading}/>
          </form>
        </div>
      }
    </section>
  );
};
  
export default NewPasswordPage;

