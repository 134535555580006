import React, { FC } from 'react';
import './SideMenu.scss';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import logoIcapps from '../../_assets/_images/logo_icapps.svg';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

type Props = {
 className?: string;
 user: string;
 onClickLogout: () => void;
 onClickItem: () => void;
}

const SideMenu: FC<Props> = ({className, user, onClickLogout, onClickItem}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      <img src={logoIcapps} className="sidemenu__logo"></img>
      <div className={`sidemenu ${className || ''}`}>
        <ul>
          <Link onClick={onClickItem} className="sidemenu__link" to="/overview">
            <li className={classNames('list-item', {active: pathname === '/overview'})}>
              <p id="projects" className="sidemenu__item">{t('titleSidebarProjects')}</p>
            </li>
          </Link>
          <Link onClick={onClickItem} className="sidemenu__link" to="/collection">
            <li className={classNames('list-item', {active: pathname === '/collection'})}>
              <p id="collection" className="sidemenu__item">{t('titleSidebarCollection')}</p>
            </li>
          </Link>  
        </ul>
        <div className="sidemenu__info">
          <a>{t('txtSidebarLoggedInUser')} <b>{user}</b></a>
        </div>
        <Button className="sidemenu__button" onClick={onClickLogout} type="button" text={t('btnSidebarLogout')}/>
      </div>
    </>
  );
};

export default SideMenu;