import { useQuery } from 'react-query';
import { HttpClient, ApiError } from '../../../shared/http';
import { ITechnology } from '../models/Technology';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getTechnologies(): Promise<ITechnology[]> {
  return await HttpClient.get<ITechnology[]>('technologies?_sort=display_order:asc,label:asc');
}
export function useGetTechnologies(): UseQueryResult<ITechnology[], ApiError> {
  return useQuery<ITechnology[], ApiError>('getTechnologies', getTechnologies);
}
