import React, { FC } from 'react';
import './Spinner.scss';

type Props = {
  loading?: boolean;
  className?: string;
};

const Spinner: FC<Props> = ({ loading, className }) => (
  <div className="loading-box">
    <div className={`loader ${className || ''}`}> {loading && <div className="loader"> </div>} </div>
  </div>
);

export default Spinner;
