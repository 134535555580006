import React, { FC } from 'react';
import './checkbox.scss';

type Props = {
  title?: string,
  checked?: boolean,
  onChange?: (e) => void,
  value?: string,
  name?: string,
  indeterminate?: boolean,
};

const Checkbox: FC<Props> = ({ title, checked, onChange, value, name, indeterminate }) => (
  <label className="checkbox-container">
    <span className="checkbox-span">{title}</span>
    <input
      ref={el => el && (el.indeterminate = indeterminate)}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
    />
    <span className="checkmark"></span>
  </label>
);

export default Checkbox;
