/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import './ErrorMessage.scss';
import classNames from 'classnames';

type Props = {
  isVisible?: boolean;
  message: string;
};

const ErrorMessage: FC<Props> = ({ isVisible, message}) => {
  if (!isVisible) {
    return null;
  }

  return <div className="irg-error">{message}</div>;
};

export default ErrorMessage;
