import React, { FC } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { UnauthorizedRoute, UnauthorizedLayout } from './routes';
import { AuthorizedRoute, AuthorizedLayout } from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <UnauthorizedRoute path="/auth" component={UnauthorizedLayout} />
          <AuthorizedRoute path="/" component={AuthorizedLayout} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
      />
    </div>
  );
};

export default App;
