import { HttpClient, ApiError } from '../../../shared/http';
import { useMutation, useQueryClient } from 'react-query';
import { ICollectionResponse, ICreateCollection } from '../models/Collection';
import { UseMutationResult } from 'react-query/types/react/types';

function createCollection(body: ICreateCollection): Promise<ICollectionResponse> {
  return HttpClient.post<ICollectionResponse>('collections', body);
}

export function useCreateCollection(): UseMutationResult<ICollectionResponse, ApiError, ICreateCollection> {
  const queryClient = useQueryClient();
  return useMutation<ICollectionResponse, ApiError, ICreateCollection>(createCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCollections');
    }
  });
}
