import { HttpClient, ApiError } from '../../../shared/http';
import { useMutation, useQueryClient } from 'react-query';
import { ICollectionResponse, IPutProjectCollection } from '../models/Collection';
import { UseMutationResult } from 'react-query/types/react/types';

function putProjectCollection({id, projects}: IPutProjectCollection): Promise<ICollectionResponse> {
  const body = {projects: projects};
  return HttpClient.put<ICollectionResponse>(`collections/${id}`, body);
}

export function usePutProjectCollection(): UseMutationResult<ICollectionResponse, ApiError, IPutProjectCollection> {
  const queryClient = useQueryClient();
  return useMutation<ICollectionResponse, ApiError, IPutProjectCollection>(putProjectCollection, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCollections');
    }
  });
}
