import React, { FC, FormEvent } from 'react';
import { ILoginForm } from 'src/models/Login';
import { useLogin } from '../../queries/useLogin';
import Button from '../../components/Button';
import FormItem from '../../components/FormItem';
import ErrorMessage from '../../components/ErrorMessage';
import { Link } from 'react-router-dom';
import useForm from '../../hooks/UseForm';
import { useTranslation } from 'react-i18next';

import './LoginPage.scss';

const initialForm: ILoginForm = {
  identifier: '',
  password: '',
};

const LoginPage: FC = () => {
  const { form, setFormAttribute } = useForm(initialForm);
  const { isLoading, isError, mutate, error } = useLogin();
  const { t } = useTranslation();

  const login = (event: FormEvent) => {
    event.preventDefault();
    mutate(form);
  };

  return (
    <section className="login-page">
      {error ? (
        <ErrorMessage
          message={error?.statusCode == 400 ? t('errorLoginAuthorisedError') : t('errorLoginUnknownError')}
          isVisible={isError}
        ></ErrorMessage>
      ) : (
        <h3 className="title">{t('titleLogin')}</h3>
      )}
      <form onSubmit={login} className="form-container">
        <FormItem
          required
          id="identifier"
          label={t('lblLoginEmail')}
          type="email"
          value={form.identifier}
          onChange={setFormAttribute}
        />
        <FormItem
          required
          id="password"
          label={t('lblLoginPassword')}
          type="password"
          value={form.password}
          onChange={setFormAttribute}
        />
        <Button type="submit" text={t('btnLogin')} loading={isLoading} />
      </form>
      <div className="label">
        <Link className="link" to="/auth/forgot-password">
          {t('btnLoginForgotPassword')}
        </Link>
      </div>
    </section>
  );
};

export default LoginPage;
