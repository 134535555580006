/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation } from 'react-query';
import { IUser } from '../models/User';
import { HttpClient } from '../../../shared/http';
import { ApiError } from '../../../shared/http/HttpError';
import { IForgotPasswordForm } from 'src/models/ForgotPassword';

function forgotPassword(body: IForgotPasswordForm): Promise<IUser> {
  return HttpClient.post<IUser>('auth/forgot-password', body);
}

export function useForgotPassword() {
  return useMutation<IUser, ApiError, IForgotPasswordForm>(forgotPassword, {
  });
}