import { useQuery } from 'react-query';
import { IPlatform } from '../models/Platform';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getPlatforms(): Promise<IPlatform[]> {
  return HttpClient.get<IPlatform[]>('platforms?_sort=display_order:asc,label:asc');
}
export function useGetPlatforms(): UseQueryResult<IPlatform[], ApiError> {
  return useQuery<IPlatform[], ApiError>('getPlatforms', getPlatforms);
}
