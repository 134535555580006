import React, { FC } from 'react';
import './Button.scss';

type Props = {
   text?: string,
   loading?: boolean,
   type: 'button' | 'submit' | 'reset',
   onClick?: () => void,
   className?: string
 }

const Button: FC<Props> = ({text, loading, type='submit', onClick, className}) => 
  <div>
    <button onClick={onClick} type={type} className={`irg-button ${className || ''}`} >{loading? <div className="loader"></div> : text}</button>
  </div>;

export default Button;