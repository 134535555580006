/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';
import { IUser } from '../models/User';
import { HttpClient, ApiError } from '../../../shared/http';

export async function getUser(): Promise<IUser> {
  return HttpClient.get<IUser>('users/me');
}
export function useGetUser() {
  return useQuery<IUser, ApiError>('getUsers', getUser);
}
