import { useHistory } from 'react-router-dom';

export function useLogout(): { logout: () => void } {
  const history = useHistory();
  const logout = () => {
    window.sessionStorage.removeItem('jwt');
    history.push('/');
  };

  return { logout };
}
