import React, { FC } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import OverviewPage from '../../pages/OverviewPage/OverviewPage';
import DetailPage from '../../pages/DetailPage/DetailPage';
import CollectionPage from '../../pages/CollectionPage/CollectionPage';
import './AuthorizedLayout.scss';

const AuthorizedLayout: FC = () => {
  return (
    <section className="auth-layout">
      <Switch>
        <Route path="/overview" exact component={OverviewPage} />
        <Route path="/collection" exact component={CollectionPage} />
        <Route path="/overview/:id" exact component={DetailPage} />
        <Redirect to="/overview" />
      </Switch>
    </section>
  );
};

export default AuthorizedLayout;
