import React, { FC } from 'react';
import './FormItem.scss';

type Props = {
  id?: string,
  label?: string,
  type?: 'email' | 'number' | 'password' | 'text'
  value?: string,
  required: boolean,
  onChange?:(value:string, id:string) => void
}

const FormItem: FC<Props> = ({id, label, type='text', value, required, onChange}) => 
  <div className="irg-form-item">
    <label htmlFor={id} className="irg-label">{label}</label>
    <input required={required} onChange={e => onChange(e.target.value, id)} value={value} type={type} id={id} className="irg-input"/>
  </div>;

export default FormItem;