import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pdficon from '../../_assets/_icons/pdficon.svg';
import removeattachment from '../../_assets/_icons/removeattachment.svg';
import Select from 'react-select';
import { getPipedrivePersons } from '../../queries/useGetPipedrivePersons';
import { useGetUser } from '../../queries/UseGetUser';
import { usePostMail } from '../../queries/usePostMail';
import { IProject } from '../../models/Project';
import AsyncCreatableSelect from 'react-select/async-creatable';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { IMail, IPersonReceiver, IPersonSender } from '../../models/Mail';
import { IPipedrivePersons } from '../../models/PipedrivePerson';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';

import './MailEdit.scss';

type Props = {
  projects: IProject[];
  onClickCancel: () => void;
  onClickContinue: () => void;
  selectedProjectsPdf: string[];
  selectedProjectsUrl: string[];
  selectPdf: (value: string) => void;
};

type SelectValue = {
  label: string;
  value: string;
  data?: IPipedrivePersons;
};

export interface RichEditorState {
  toHTML: () => string;
}

const templates = [
  { value: 'eng', label: 'English' },
  { value: 'nl', label: 'Nederlands' },
];

const MailEdit: FC<Props> = ({ onClickCancel, selectedProjectsPdf, selectedProjectsUrl, selectPdf, projects }) => {
  const { t } = useTranslation();
  const [templateValue, setTemplateValue] = useState<{ value: string; label: string }>(templates[0]);
  const [editorState, setEditorState] = useState<RichEditorState>(null);
  const [selectedPersons, setSelectedPersons] = useState<SelectValue[]>();
  const [subject, setSubject] = useState<string>(String(t('textMailEditSubject')));
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const { data: dataUser } = useGetUser();

  const urlArray = selectedProjectsUrl.map(urlId => projects.find(project => String(project.id) === urlId));
  const pdfArray = selectedProjectsPdf.map(pdfId => projects.find(project => String(project.id) === pdfId));

  const { mutate, isLoading } = usePostMail(onClickCancel);

  const getEmailSelectOptions = async (inputValue: string) => {
    const persons = await getPipedrivePersons(inputValue);
    return persons.map(p => ({ label: p.name + ' <' + p.emails[0] + '>', value: p.emails[0], data: p }));
  };

  const handleSendButtonClicked = () => {
    if (selectedPersons) {
      const html = editorState.toHTML();
      const sender: IPersonSender = {
        email: dataUser.email,
        name: dataUser.username,
      };

      //fix
      const receivers: IPersonReceiver[] = selectedPersons.map(p => ({
        email: p.value,
        name: p.data?.name,
        type: 'to',
      }));

      const bodySendEmail: IMail = {
        to: receivers,
        from: sender,
        subject: subject,
        htmlBody: html,
        pdfProjectIds: pdfArray.map(pdf => String(pdf.id)),
      };

      mutate(bodySendEmail);
    } else {
      setIsValidEmail(false);
    }
  };

  const handlePersonSelected = (selectedValues: SelectValue[]) => {
    setSelectedPersons(selectedValues);
    setIsValidEmail(true);
  };

  //model
  const setEmailMessage = useCallback(
    (languageCode: string) => {
      const selectedPdfs = pdfArray.filter(pdf => selectedProjectsPdf.includes(String(pdf.id)));
      const messageParts: string[] = [];
      messageParts.push(
        t(`email_template_${languageCode}_1`, {
          client: selectedPersons
            ?.filter(p => !!p.data)
            .map(p => p.data.name)
            .join(', '),
        }),
      );
      if (urlArray.length) {
        messageParts.push(
          t(`email_template_${languageCode}_2`, {
            urlCases: urlArray
              .map(
                urlId =>
                  `<ul><li><a key=${urlId.id} target="_blank" rel="noopener noreferrer" href=${urlId.website_case_link}>${urlId.name}</a></li></ul>`,
              )
              .join(''),
          }),
        );
      }
      if (selectedPdfs.length) {
        messageParts.push(t(`email_template_${languageCode}_3`, { pdfCases: selectedPdfs.map(pdfId => pdfId.name).join(', ') }));
      }

      messageParts.push(t(`email_template_${languageCode}_4`, { sender: String(dataUser?.username) }));
      setEditorState(BraftEditor.createEditorState(messageParts.join('')));
    },
    [projects, selectedPersons, selectedProjectsPdf, dataUser],
  );

  useEffect(() => {
    setEmailMessage(templateValue.value);
  }, [setEmailMessage, templateValue]);

  return (
    <div className="mail-edit">
      <div className="edit-container">
        <div className="edit-title">{t('titleMailEdit')}</div>
        <div className="edit-item">
          <label className="edit-label">{t('lblEditMailTo')}</label>
          <AsyncCreatableSelect
            formatCreateLabel={userInput =>
              t('textMailEditSelectAddEmail', {
                client: `'${userInput}'`,
              })
            }
            className="async-select"
            isMulti
            cacheOptions
            loadOptions={getEmailSelectOptions}
            value={selectedPersons}
            onChange={handlePersonSelected}
          />
          <div className="error-mail">
            <ErrorMessage message={t('ErrorEditEmailNoEmail')} isVisible={!isValidEmail} />
          </div>
        </div>
        <div className="edit-item">
          <label onChange={() => setSubject} className="edit-label">
            {t('lblEditMailSubject')}
          </label>
          <input defaultValue={subject} onChange={e => setSubject(e.target.value)} required className="input" />
        </div>
        <div className="edit-item">
          <label className="edit-label">{t('lblEditMailAttachment')}</label>
          <div className="attachment-container">
            {selectedProjectsPdf.map(pdfId => {
              const project = projects.find(project => String(project.id) === pdfId);
              return (
                <div className="attachment-item" key={project.id}>
                  <img src={pdficon} className="attachment-image"></img>
                  <span className="attachment-title">{project.name}</span>
                  <img src={removeattachment} className="attachment-remove" onClick={() => selectPdf(pdfId)}></img>
                </div>
              );
            })}
          </div>
        </div>
        <div className="edit-item">
          <label className="edit-label">{t('lblEditMailTemplate')}</label>
          <Select
            className="react-select-template"
            isSearchable={false}
            isClearable={false}
            options={templates}
            value={templateValue}
            onChange={selectedLanguage => {
              setTemplateValue(selectedLanguage);
            }}
          />
        </div>
        <div className="edit-item">
          <label className="edit-label">{t('lblEditMailMessage')}</label>
          <BraftEditor
            language={'en'}
            controls={['underline', 'emoji', 'list-ul', 'link']}
            value={editorState}
            onChange={setEditorState}
          />
        </div>
        <div className="buttons-container">
          <Button text={t('btnMailFormatCancel')} type="button" className="button-send-cancel" onClick={onClickCancel}></Button>
          <Button
            text={t('btnEditMailSend')}
            loading={isLoading}
            type="button"
            className="button-send-continue"
            onClick={handleSendButtonClicked}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default MailEdit;
