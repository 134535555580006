import React, { FC, useCallback, useEffect, useState } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useGetProjects } from '../../queries/useGetProjects';
import GridItem from '../../components/GridItem';
import { Link, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import filterLogo from '../../_assets/_icons/filterlogo.svg';
import closeFilterLogo from '../../_assets/_icons/closefiltermenu.svg';
import FilterMenu from '../../components/FilterMenu';
import { ProjectQueryParams } from '../../models/ProjectQueryParams';
import { useTranslation } from 'react-i18next';
import SideMenu from '../../components/SideMenu';
import { useLogout } from '../../queries/useLogout';
import { useGetUser } from '../../queries/UseGetUser';
import icappsLogo from '../../_assets/_images/logo_icapps_overview.svg';
import burgerMenu from '../../_assets/_icons/burgermenu.svg';
import closeMenu from '../../_assets/_icons/closemenu.svg';
import classNames from 'classnames';
import sortLogo from '../../_assets/_icons/sortlogo.svg';
import { ISort } from '../../models/Sort';
import RadioButton from '../../components/RadioButton';
import debounce from 'lodash.debounce';
import SearchBar from '../../components/SearchBar';

import './OverviewPage.scss';

const sortMethods: ISort = {
  DATE_ASC: 'published_at:asc',
  DATE_DESC: 'published_at:desc',
  NAME_ASC: 'name:asc',
  NAME_DESC: 'name:desc',
};

const OverviewPage: FC = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<ProjectQueryParams>({});
  const [sort, setSort] = useState(sortMethods.DATE_DESC);
  const [search, setSearch] = useState('');
  const { data, isLoading, refetch } = useGetProjects(sort, query, search);
  const { logout } = useLogout();
  const { data: dataUser } = useGetUser();
  const { pathname } = useLocation();
  const [isOpenNavMenu, setIsOpenNavMenu] = useState(false);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const numberOfFilters = Object.keys(query).filter(key => !!query[key].length).length;
  const debouncedRefetch = debounce(refetch, 500);

  const dropdownOptions = useDropdownMenu(4);

  useEffect(() => {
    setIsOpenNavMenu(false);
  }, [pathname]);

  useEffect(() => {
    debouncedRefetch();
  }, [query, search]);

  useEffect(() => {
    refetch();
  }, [sort]);

  const resetFilter = () => {
    setQuery({});
    setSearch('');
  };

  const handleSortChanged = e => {
    const { value } = e.target;
    setSort(value);
    dropdownOptions.setIsOpen(false);
  };

  const renderEnvBadge = useCallback(() => {
    const env = process.env.REACT_APP_ENV;
    if (env === 'development' || env === 'staging') {
      return <span className={`env-badge${env === 'development' ? ' dev' : ' staging'}`}>
        {env === 'development' ? t('lblDev') : t('lblStaging')}
      </span>;
    }
    
    return null;
  }, [process.env.REACT_APP_ENV]);

  return (
    <section className="overview-page">
      <div className="page">
        <div className={classNames('header-container', { 'right-open': isFilterSidebarOpen })}>
          <img onClick={() => setIsOpenNavMenu(true)} className="icappslogo" src={icappsLogo} />
          <SearchBar value={search} onChange={(e) => setSearch(e.target.value)} />
          <div className="container">
            <div className="menu-filter">
              <div
                className={classNames('filter-button', { selected: numberOfFilters > 0 })}
                onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
              >
                <img src={filterLogo} className="filter-logo" />
                <div className="filter-text">{t('btnFilter')}</div>
                {numberOfFilters > 0 && <div className="filter-applied">{numberOfFilters}</div>}
              </div>
            </div>

            <div
              className={classNames('sort-button', { selected: dropdownOptions.isOpen })}
              onClick={() => dropdownOptions.setIsOpen(!dropdownOptions.isOpen)}
              {...dropdownOptions.buttonProps}
            >
              <img className="sort-logo" src={sortLogo} />
              <div>{t('btnSort')}</div>
            </div>
            <div
              className="sort-container"
              role="menu"
              style={{ visibility: dropdownOptions.isOpen ? 'visible' : 'hidden' }}
            >
              <div className="sort-sortby-text">{t('titleSort')}</div>
              <RadioButton
                name="radio"
                title={t('lblSortDateNewest')}
                checked={sort === sortMethods.DATE_DESC}
                onChange={handleSortChanged}
                value={sortMethods.DATE_DESC}
                {...dropdownOptions.itemProps[0]}
              />
              <RadioButton
                name="radio"
                title={t('lblSortDateOldest')}
                checked={sort === sortMethods.DATE_ASC}
                onChange={handleSortChanged}
                value={sortMethods.DATE_ASC}
                {...dropdownOptions.itemProps[1]}
              />
              <RadioButton
                name="radio"
                title={t('lblSortNameASC')}
                checked={sort === sortMethods.NAME_ASC}
                onChange={handleSortChanged}
                value={sortMethods.NAME_ASC}
                {...dropdownOptions.itemProps[2]}
              />
              <RadioButton
                name="radio"
                title={t('lblSortNameDESC')}
                checked={sort === sortMethods.NAME_DESC}
                onChange={handleSortChanged}
                value={sortMethods.NAME_DESC}
                {...dropdownOptions.itemProps[3]}
              />
            </div>
          </div>
        </div>

        <div className="menu-sidemenu">
          <Menu
            isOpen={isOpenNavMenu}
            onClose={() => setIsOpenNavMenu(false)}
            onOpen={() => setIsOpenNavMenu(true)}
            itemListElement="div"
            width={'35rem'}
            disableAutoFocus
            customBurgerIcon={<img src={burgerMenu} />}
            customCrossIcon={<img src={closeMenu} />}
          >
            <SideMenu user={dataUser?.username} onClickLogout={logout} onClickItem={() => setIsOpenNavMenu(false)} />
          </Menu>
        </div>
        <div>
          <div className="menu-filter">
            <Menu
              isOpen={isFilterSidebarOpen}
              onClose={() => setIsFilterSidebarOpen(false)}
              onOpen={() => setIsFilterSidebarOpen(true)}
              right
              itemListElement="div"
              width={'28.0rem'}
              disableAutoFocus
              customBurgerIcon={<img src={filterLogo} />}
              customCrossIcon={<img src={closeFilterLogo} />}
            >
              <FilterMenu setQuery={setQuery} onClick={resetFilter} query={query} />
            </Menu>
          </div>
          <div className="page-wrap">
            {isLoading ? (
              <ul className="list">
                {new Array(12).fill(null).map((_, i) => (
                  <div className="loading-div" key={i} />
                ))}
              </ul>
            ) : data?.length == 0 ? (
              <div className={classNames('no-results-container', { 'right-open': isFilterSidebarOpen })}>
                <div className="no-results-title">{t('txtFilterNoResults')}</div>
                <button className="no-results-button" onClick={resetFilter}>
                  {t('btnFilterReset')}
                </button>
              </div>
            ) : (
              <ul className="list">
                {data?.map(project => {
                  return (
                    <Link className="link" to={`/overview/${project.id}`} key={project.id}>
                      <GridItem project={project} />
                    </Link>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewPage;
