import { useQuery } from 'react-query';
import { IPipedrivePersons } from '../models/PipedrivePerson';
import { HttpClient, ApiError } from '../../../shared/http';

export async function getPipedrivePersons(search: string): Promise<IPipedrivePersons[]> {
  let result;
  if (search.length < 3) {
    result = [];
  } else {
    result = await HttpClient.get<IPipedrivePersons[]>(`pipedrive/persons/search?term=${search}`);
  }

  return result;
}

export function useGetPipedrivePersons(search: string) {
  return useQuery<IPipedrivePersons[], ApiError>(['getPipedrivePersons', search], () => getPipedrivePersons(search));
}
