import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ILoginResponse } from '../models/User';
import { ApiError, HttpClient } from '../../../shared/http';
import { ILoginForm } from '../models/Login';
import { UseMutationResult } from 'react-query/types/react/types';

function login(body: ILoginForm): Promise<ILoginResponse> {
  return HttpClient.post<ILoginResponse>('auth/local', body);
}

export function useLogin(): UseMutationResult<ILoginResponse, ApiError, ILoginForm> {
  const history = useHistory();
  return useMutation<ILoginResponse, ApiError, ILoginForm>(login, {
    onSuccess: (response) => {
      window.sessionStorage.setItem('jwt', response.jwt);
      history.push('/overview');
    },
  });
}
