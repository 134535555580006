import { useQuery } from 'react-query';
import { IProject } from '../models/Project';
import { HttpClient, ApiError } from '../../../shared/http';
import { ProjectQueryParams } from '../models/ProjectQueryParams';
import queryString from 'query-string';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getProjects(sort: string, query?: ProjectQueryParams, search?: string): Promise<IProject[]> {
  const { budget_gt, budget_lt, ...otherQueryParams } = query;
  const newQuery = otherQueryParams as ProjectQueryParams;
  if (!isNaN(budget_gt)) {
    newQuery.budget_gt = budget_gt;
  }
  if (!isNaN(budget_lt)) {
    newQuery.budget_lt = budget_lt;
  }
  return HttpClient.get<IProject[]>(
    `projects?${queryString.stringify(newQuery)}${search ? `&name_contains=${search}` : ''}&_sort=${sort}`,
  );
}

export function useGetProjects(sort: string, query?: ProjectQueryParams, search?: string): UseQueryResult<IProject[], ApiError> {
  return useQuery<IProject[], ApiError>('getProjects', () => getProjects(sort, query, search));
}
