import React, { FC, ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
  component: FC;
  path: string;
}

const AuthorizedRoute: FC<Props> = ({ component: Component, ...otherProps }) => {
  const renderRoute = (): ReactNode => {
    const RouteComponent = Component;

    if (window.sessionStorage.getItem('jwt')) {
      return <RouteComponent />;
    } else {
      return <Redirect to="/auth/login" />;
    }
  };

  return (
    <Route
      {...otherProps}
      render={renderRoute}
    />
  );
};

export default AuthorizedRoute;
