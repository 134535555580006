import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from '../../pages/LoginPage/LoginPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import NewPasswordPage from '../../pages/NewPasswordPage/NewPasswordPage';
import './UnauthorizedLayout.scss';
import logoReferenceApp from '../../_assets/_images/logo_reference_app.svg';

const UnauthorizedLayout: FC = () => {
  return (
    <section className="unauth-layout">
      <div className="login-view">

        <div className="login-view-logo">
          <img className="login-view-image" src={logoReferenceApp} alt="icapps reference app logo" />
        </div>

        <div className="login-view-contents">
          <Switch>
            <Route path="/auth/login" exact component={LoginPage} />
            <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
            <Route path="/auth/new-password" component={NewPasswordPage} />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
      </div>
    </section>
  );
};

export default UnauthorizedLayout;
