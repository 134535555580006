import React, { FC } from 'react';
import { IProject } from 'src/models/Project';
import Checkbox from '../Checkbox';
import { useTranslation } from 'react-i18next';

import './MailFormat.scss';

type Props = {
  onClickCancel: () => void;
  onClickContinue: () => void;
  projects: IProject[];
  isAllPdfChecked: boolean;
  isAllUrlChecked: boolean;
  selectedProjectsPdf: string[];
  clearPdf: () => void;
  selectPdf: (value: string) => void;
  setPdf: (value: string[]) => void;
  selectedProjectsUrl: string[];
  clearUrl: () => void;
  selectUrl: (value: string) => void;
  setUrl: (value: string[]) => void;
};

const MailFormat: FC<Props> = ({
  onClickCancel,
  onClickContinue,
  projects,
  isAllPdfChecked,
  isAllUrlChecked,
  selectedProjectsPdf,
  clearPdf,
  selectPdf,
  setPdf,
  selectedProjectsUrl,
  clearUrl,
  selectUrl,
  setUrl,
}) => {
  const { t } = useTranslation();

  const urlProjects = projects.filter(project => !!project.website_case_link);

  return (
    <div className="mail-format">
      <div className="format-container">
        <div className="format-title">{t('titleMailFormat')}</div>
        <div className="format-text">{t('textMailFormat')}</div>
        <div className="project-list">
          <table className="table-projects">
            <thead>
              <tr>
                <th></th>
                <th className="checkbox">
                  <Checkbox
                    checked={isAllPdfChecked}
                    indeterminate={!isAllPdfChecked && selectedProjectsPdf.length > 0}
                    title="PDF"
                    name="PDF"
                    onChange={() => {
                      if (isAllPdfChecked) {
                        clearPdf();
                      } else {
                        setPdf(projects.map(project => String(project.id)));
                      }
                    }}
                  />
                </th>
                {urlProjects.length ? (
                  <th className="checkbox">
                    <Checkbox
                      checked={isAllUrlChecked}
                      indeterminate={!isAllUrlChecked && selectedProjectsUrl.length > 0}
                      title="URL"
                      name="URL"
                      onChange={() => {
                        if (isAllUrlChecked) {
                          clearUrl();
                        } else {
                          setUrl(urlProjects.map(project => String(project.id)));
                        }
                      }}
                    />
                  </th>
                ) : (
                  <th></th>
                )}
              </tr>
            </thead>
            <tbody>
              {projects?.map(project => (
                <tr className="project-item" key={project.id}>
                  <td>
                    <div className="project-title">{project?.name}</div>
                  </td>
                  <td className="checkbox">
                    <Checkbox
                      onChange={() => selectPdf(String(project.id))}
                      checked={selectedProjectsPdf.includes(String(project.id))}
                      name="PDF"
                    />
                  </td>
                  {project.website_case_link ? (
                    <td className="checkbox">
                      <Checkbox
                        onChange={() => selectUrl(String(project.id))}
                        checked={selectedProjectsUrl.includes(String(project.id))}
                        name="URL"
                      />
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="buttons-container">
          <button className="button-format-cancel" onClick={onClickCancel}>
            {t('btnMailFormatCancel')}
          </button>
          <button className="button-format-continue" onClick={onClickContinue}>
            {t('btnMailFormatContinue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MailFormat;
