/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation } from 'react-query';
import { IUser } from '../models/User';
import { ApiError, HttpClient } from '../../../shared/http';
import { INewPasswordRequestBody } from 'src/models/NewPassword';

function newPassword(body: INewPasswordRequestBody): Promise<IUser> {
  return HttpClient.post<IUser>('auth/reset-password', body);
}

export function useNewPassword() {
  return useMutation<IUser, ApiError, INewPasswordRequestBody>(newPassword);
}
