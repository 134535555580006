import { useQuery } from 'react-query';
import { HttpClient, ApiError } from '../../../shared/http';
import { ICollectionResponse } from '../models/Collection';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getCollections(ownerId?: string): Promise<ICollectionResponse[]> {
  if (!ownerId) {
    return [];
  }
  return HttpClient.get<ICollectionResponse[]>(`collections?owner=${ownerId}`);
}

export function useGetCollections(ownerId?: string): UseQueryResult<ICollectionResponse[], ApiError> {
  return useQuery<ICollectionResponse[], ApiError>(
    ['getCollections', ownerId],
    () => getCollections(ownerId),
    { enabled: !!ownerId }
  );
}
