import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useGetProject } from '../../queries/useGetProject';
import closeMenuIcon from '../../_assets/_icons/closefiltermenu.svg';
import closeFullscreenIcon from '../../_assets/_icons/closefullscreen.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import { useTranslation } from 'react-i18next';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import classNames from 'classnames';
import { capitalize, numberWithCommas } from '../../../../shared/utils/index';
import addToCollectionIcon from '../../_assets/_icons/addtocollection.svg';
import sendproject from '../../_assets/_icons/sendproject.svg';
import removeFromCollectionIcon from '../../_assets/_icons/removefromcollection.svg';
import { useCreateCollection } from '../../queries/useCreateCollection';
import { IPutProjectCollection, ICreateCollection } from '../../models/Collection';
import { useGetUser } from '../../queries/UseGetUser';
import { usePutProjectCollection } from '../../queries/usePutProjectCollection';
import { useGetCollections } from '../../queries/useGetCollections';
import Mail from '../../components/Mail';

import './DetailPage.scss';
import Spinner from '../../components/Spinner';

SwiperCore.use([Navigation, Pagination, Keyboard]);

const DetailPage: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data } = useGetProject(id);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [formatEmail, setFormatEmail] = useState(false);
  const history = useHistory();
  const { data: dataUser } = useGetUser();
  const { mutate: createCollection } = useCreateCollection();
  const { mutate: putProjectCollection } = usePutProjectCollection();
  const { data: collectionsData } = useGetCollections(dataUser?.id);

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  if (id !== String(data?.id)) {
    return <Spinner loading={true} />;
  }

  const toggleProjectInCollection = (remove: boolean) => {
    if (collectionsData.length) {
      const projectIds = collectionsData[0].projects.map(p => p.id);
      const projects = remove
        ? projectIds.filter(projectId => projectId !== parseInt(id))
        : [...projectIds, parseInt(id)];

      const paramsAddProject: IPutProjectCollection = {
        id: collectionsData[0].id,
        projects,
      };
      putProjectCollection(paramsAddProject);
    } else {
      const ParamsCreateCollection: ICreateCollection = {
        name: `Collection ${dataUser?.username}`,
        owner: dataUser?.id,
        projects: [parseInt(id)],
      };
      createCollection(ParamsCreateCollection);
    }
  };

  const handleFullscreenBgClicked = (evt) => {
    if (
      !evt.target.classList.contains('image-fullscreen') &&
      !evt.target.classList.contains('swiper-button-prev') &&
      !evt.target.classList.contains('swiper-button-next')
    ) {
      setIsFullScreen(false);
    }
  };

  return (
    <section className="detail-page">
      <img onClick={history.goBack} className="close" src={closeMenuIcon} />
      {collectionsData?.[0]?.projects?.some(p => p.id === parseInt(id)) ? (
        <div className="remove-from-collection-button" onClick={() => toggleProjectInCollection(true)}>
          <img className="remove-from-collection-logo" src={removeFromCollectionIcon} />
          <div className="remove-from-collection-text">{t('btnDetailRemoveProjectFromCollection')}</div>
        </div>
      ) : (
        <div className="add-to-collection-button" onClick={() => toggleProjectInCollection(false)}>
          <img className="add-to-collection-logo" src={addToCollectionIcon} />
          <div className="add-to-collection-text">{t('btnDetailAddProjectToCollection')}</div>
        </div>
      )}
      <div className="send-button" onClick={() => setFormatEmail(true)}>
        <img className="send-button-logo" src={sendproject}></img>
        <div className="send-button-text">{t('btnDetailSendProject')}</div>
      </div>
      <div className="wrapper">
        <h1>{data?.name}</h1>
        <div className="half-width page-break">
          <div>
            {data?.description?.split('\n\n').map(function (item, key) {
              return <p key={key}>{item}</p>;
            })}
            {data?.website_case_link ? (
              <Link to={{ pathname: data?.website_case_link?.toString() }} target="_blank">
                <button className="button-website">{t('btnDetailLearnMore')}</button>
              </Link>
            ) : (
              <p></p>
            )}
          </div>
          <div className="quote">
            <p className="quote_text">{data?.quote_text}</p>
            <p className="quote_subtext">{data?.quote_subtext}</p>
          </div>
        </div>
        {!!data?.images.length && (
          <Swiper className="swiper-container"
            navigation
            spaceBetween={30}
            slidesPerView="auto"
            watchOverflow={false}>
            {data?.images.map(image => (
              <SwiperSlide key={image.id}>
                <img
                  className={classNames('image', { selected: isFullScreen })}
                  src={image.url}
                  onClick={() => setIsFullScreen(true)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {isFullScreen && (
          <div onClick={handleFullscreenBgClicked}>
            <img onClick={() => setIsFullScreen(false)} className="close-fullscreen" src={closeFullscreenIcon} />
            <Swiper
              className="swiper-container-fullscreen"
              loop={true}
              navigation
              pagination={{ type: 'fraction' }}
              spaceBetween={0}
              slidesPerView={1}
              keyboard={{ enabled: true }}
            >
              {data?.images.map(image => (
                <SwiperSlide key={image.id}>
                  <img
                    className={classNames('image-fullscreen', { selected: isFullScreen })}
                    src={image.url}
                    onClick={() => setIsFullScreen(true)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {formatEmail && <Mail onClickCancel={() => setFormatEmail(false)} projects={[data]} />}

        <div className="extra-info page-break">
          <h2>{t('titleDetailKeyInformation')}</h2>
          <dl className="title-medium">
            <dt>{t('lblPlatform')}</dt>
            {data?.platforms.length > 0
              ? <dd>{data?.platforms?.map(platform => platform.label)?.join(', ')}</dd>
              : <dd> - </dd>}
            <dt>{t('lblTechnology')}</dt>
            {data?.technologies.length > 0
              ? <dd>{data?.technologies?.map(technology => technology.label)?.join(', ')}</dd>
              : <dd> - </dd>}
            <dt>{t('lblSector')}</dt>
            {data?.sector
              ? <dd>{data?.sector?.label}</dd>
              : <dd> - </dd>}
          </dl>
          <dl className="narrow-list-first title-medium">
            <dt>{t('lblBudget')}</dt>
            {data?.budget
              ? <dd>€ {numberWithCommas(data?.budget)}</dd>
              : <dd> - </dd>}
            <dt>{t('lblTeam')}</dt>
            {data?.teams.length > 0
              ? <dd>{data?.teams?.map(team => team.name)}</dd>
              : <dd> - </dd>}
          </dl>
          <dl className="narrow-list title-medium">
            <dt>{t('lblDuration')}</dt>
            {data?.duration_amount
              ? <dd>{data?.duration_amount} {data?.duration_unit}</dd>
              : <dd> - </dd>}
            <dt>{t('lblType')}</dt>
            {data?.type
              ? <dd>{capitalize(data?.type)}</dd>
              : <dd> - </dd>}
          </dl>
          <dl className="narrow-list title-wide">
            <dt>{t('lblCarePackage')}</dt>
            {data?.services.length > 0
              ? <dd>{data?.services.map(service => service.label)?.join(', ')}</dd>
              : <dd> - </dd>}
            <dt>{t('lblAccountManager')}</dt>
            {data?.account_manager
              ? <dd>{data?.account_manager.name}</dd>
              : <dd> - </dd>}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default DetailPage;
