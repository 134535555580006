import { useQuery } from 'react-query';
import { ITeam } from '../models/Team';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getTeams(): Promise<ITeam[]> {
  return await HttpClient.get<ITeam[]>('teams?_sort=display_order:asc,name:asc');
}
export function useGetTeams(): UseQueryResult<ITeam[], ApiError> {
  return useQuery<ITeam[], ApiError>('getTeams', getTeams);
}
