import React, { FC, useState } from 'react';
import { IProject } from 'src/models/Project';
import { useTranslation } from 'react-i18next';
import useSelectArray from '../../hooks/useSelectArray';
import MailFormat from '../MailFormat';
import MailEdit from '../MailEdit';

import './Mail.scss';

type Props = {
  projects: IProject[];
  onClickCancel: () => void;
};

const Mail: FC<Props> = ({ onClickCancel, projects }) => {
  const { t } = useTranslation();
  const [editMail, setEditMail] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [formatMail, setFormatMail] = useState(true);
  const { selectArray: selectedProjectsPdf, clear: clearPdf, select: selectPdf, set: setPdf } = useSelectArray([]);
  const { selectArray: selectedProjectsUrl, clear: clearUrl, select: selectUrl, set: setUrl } = useSelectArray([]);
  const isAllPdfChecked = projects.length === selectedProjectsPdf.length;
  const isAllUrlChecked = projects.filter(project => !!project.website_case_link).length === selectedProjectsUrl.length;

  const showEditMail = () => {
    setEditMail(true);
    setFormatMail(false);
  };

  return (
    <div className="mail">
      {formatMail && (
        <MailFormat
          projects={projects}
          onClickCancel={onClickCancel}
          onClickContinue={showEditMail}
          isAllPdfChecked={isAllPdfChecked}
          isAllUrlChecked={isAllUrlChecked}
          selectedProjectsPdf={selectedProjectsPdf}
          clearPdf={clearPdf}
          selectPdf={selectPdf}
          setPdf={setPdf}
          selectedProjectsUrl={selectedProjectsUrl}
          clearUrl={clearUrl}
          selectUrl={selectUrl}
          setUrl={setUrl}
        />
      )}
      {editMail && (
        <MailEdit
          projects={projects}
          selectPdf={selectPdf}
          selectedProjectsUrl={selectedProjectsUrl}
          selectedProjectsPdf={selectedProjectsPdf}
          onClickCancel={onClickCancel}
          onClickContinue={() => setSendMail(true)}
        />
      )}
    </div>
  );
};

export default Mail;
