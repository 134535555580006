import { useQuery } from 'react-query';
import { IService } from '../models/Service';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getCarePackages(): Promise<IService[]> {
  return HttpClient.get<IService[]>('services?_sort=display_order:asc,label:asc');
}
export function useGetCarePackages(): UseQueryResult<IService[], ApiError> {
  return useQuery<IService[], ApiError>('getCarePackages', getCarePackages);
}
