import React, { FC } from 'react';
import './RadioButton.scss';

type Props = {
  title?: string,
  checked?: boolean,
  onChange?: (e) => void,
  value?: string,
  name?: string,
};

const RadioButton: FC<Props> = ({title, checked, onChange, value, name}) => (
  <label className="radio-container">
    <span>{title}</span>
    <input
      type="radio"
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
    />
    <span className="radio-input"></span>
  </label>
);

export default RadioButton;
