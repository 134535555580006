import { useQuery } from 'react-query';
import { IAccountManager } from '../models/Project';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getAccountManagers(): Promise<IAccountManager[]> {
  return HttpClient.get<IAccountManager[]>('account-managers?_sort=name:asc');
}
export function useGetAccountManagers(): UseQueryResult<IAccountManager[], ApiError> {
  return useQuery<IAccountManager[], ApiError>('getAccountManagers', getAccountManagers);
}
