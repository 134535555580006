import { useQuery } from 'react-query';
import { ISector } from '../models/Sector';
import { HttpClient, ApiError } from '../../../shared/http';
import { UseQueryResult } from 'react-query/types/react/types';

export async function getSectors(): Promise<ISector[]> {
  return HttpClient.get<ISector[]>('sectors?_sort=display_order:asc,label:asc');
}
export function useGetSectors(): UseQueryResult<ISector[], ApiError> {
  return useQuery<ISector[], ApiError>('getSectors', getSectors);
}
