import { useState, useEffect, useCallback } from 'react';
import { ApiError } from '../../../shared/http';

interface UseFormReturn<T> {
  form: T,
  setForm: (form: T) => void,
  setFormAttribute: (value: unknown, name: string) => void,
  error: ApiError,
  setError: (error: ApiError) => void,
  clearForm: () => void
}

function useForm<T>(initial: T, shouldClear?: boolean): UseFormReturn<T> {
  const [form, setForm] = useState<T>(initial);
  const [error, setError] = useState<ApiError>(null);
  const setFormAttribute = useCallback(
    (value: unknown, name: string) => {
      setForm({ ...form, [name]: value });
    },
    [form],
  );
  const clearForm = useCallback(() => {
    setForm(initial);
  }, [initial]);
  const clearError = useCallback(() => {
    setError(null);
  }, []);
  useEffect(() => {
    return () => {
      clearForm();
      clearError();
    };
  }, [shouldClear, clearForm, clearError]);
  return {
    form,
    setForm,
    setFormAttribute,
    error,
    setError,
    clearForm,
  };
}

export default useForm;





